.wrapper {
    .Polaris-Card {
        margin-top: 1rem;
    }

    .tabs {
        .Polaris-Stack__Item {
            margin-top: 0;
        }
    }
}

.login {
    .Polaris-Tabs__Tab {
        margin-top: 6px;
    }
}

.loginSignUp {
    .Polaris-Card__Section {
        padding-bottom: 0px;
    }
}

.unconnected {
    .Polaris-Card {
        background-color: #F6F6F7;
    }

    .Polaris-Button {
        --p-action-primary: #6AD5B8;
    }
}

.hs-input{
    background-color: white
}

.Polaris-IndexTable__ScrollBarContainer {
    display: none;
}

.icon-play {
    .Polaris-Icon {
        width: 50px;
        height: 50px;
    }
    .Polaris-Button {
        border-radius: 100%;
    }
}

.icon-maximize {
    .Polaris-Icon {
        width: 35px;
        height: 35px;
    }
    .Polaris-Button {
        border-radius: 100%;
    }
}


.stats-wrapper {
    .Polaris-Card {
        width: 260px;
    }

    .stats-heading {
        .Polaris-Heading {
            font-size: 1.5rem;
            line-height: 1.8rem;
            font-weight: 600;
        }
        padding-bottom: 1rem;
    }
}

.index-table-header-batch {
    .Polaris-IndexTable__TableCell--first { display:none; } 
}

.globalDataTable {
    .Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell{
        border-top: none
    }
}

.noPaddingDataTable {

    tbody {
        .Polaris-DataTable__TableRow + .Polaris-DataTable__TableRow .Polaris-DataTable__Cell{
            border-top: 1px solid rgba(225, 227, 229, 1)
        }
    }

    thead {
        visibility: hidden;
        .Polaris-DataTable__Cell {
            padding: 0px;
            border-bottom: none;
        }
    }

    .Polaris-DataTable__Cell {
      padding-top: 16px;
      padding-left: 0px;
      padding-bottom: 16px;
    }

    th:last-child {
        padding-right: 0px;
      }

    th:not(:last-child) {
      padding-right: 16px;
    }

    td:last-child {
      padding-right: 0px;
    }

    td:not(:last-child) {
      padding-right: 32px;
    }
}

.Polaris-Frame, .Polaris-Sheet, .Polaris-Modal-Dialog__Modal, .Polaris-Popover__Wrapper {
    --p-background: rgba(246, 246, 247, 1);
    --p-background-hovered: rgba(241, 242, 243, 1);
    --p-background-pressed: rgba(237, 238, 239, 1);
    --p-background-selected: rgba(237, 238, 239, 1);
    --p-surface: rgba(255, 255, 255, 1);
    --p-surface-neutral: rgba(228, 229, 231, 1);
    --p-surface-neutral-hovered: rgba(219, 221, 223, 1);
    --p-surface-neutral-pressed: rgba(201, 204, 208, 1);
    --p-surface-neutral-disabled: rgba(241, 242, 243, 1);
    --p-surface-neutral-subdued: rgba(246, 246, 247, 1);
    --p-surface-subdued: rgba(250, 251, 251, 1);
    --p-surface-disabled: rgba(250, 251, 251, 1);
    --p-surface-hovered: rgba(246, 246, 247, 1);
    --p-surface-pressed: rgba(241, 242, 243, 1);
    --p-surface-depressed: rgba(237, 238, 239, 1);
    --p-surface-search-field: rgba(241, 242, 243, 1);
    --p-backdrop: rgba(0, 0, 0, 0.5);
    --p-overlay: rgba(255, 255, 255, 0.5);
    --p-shadow-from-dim-light: rgba(0, 0, 0, 0.2);
    --p-shadow-from-ambient-light: rgba(23, 24, 24, 0.05);
    --p-shadow-from-direct-light: rgba(0, 0, 0, 0.15);
    --p-hint-from-direct-light: rgba(0, 0, 0, 0.15);
    --p-border: rgba(140, 145, 150, 1);
    --p-border-neutral-subdued: rgba(186, 191, 195, 1);
    --p-border-hovered: rgba(153, 158, 164, 1);
    --p-border-disabled: rgba(210, 213, 216, 1);
    --p-border-subdued: rgba(201, 204, 207, 1);
    --p-border-depressed: rgba(87, 89, 89, 1);
    --p-border-shadow: rgba(174, 180, 185, 1);
    --p-border-shadow-subdued: rgba(186, 191, 196, 1);
    --p-divider: rgba(225, 227, 229, 1);
    --p-icon: rgba(92, 95, 98, 1);
    --p-icon-hovered: rgba(26, 28, 29, 1);
    --p-icon-pressed: rgba(68, 71, 74, 1);
    --p-icon-disabled: rgba(186, 190, 195, 1);
    --p-icon-subdued: rgba(140, 145, 150, 1);
    --p-text: rgba(32, 34, 35, 1);
    --p-text-disabled: rgba(140, 145, 150, 1);
    --p-text-subdued: rgba(109, 113, 117, 1);
    --p-interactive: rgba(44, 110, 203, 1);
    --p-interactive-disabled: rgba(189, 193, 204, 1);
    --p-interactive-hovered: rgba(31, 81, 153, 1);
    --p-interactive-pressed: rgba(16, 50, 98, 1);
    --p-icon-interactive: rgba(44, 110, 203, 1);
    --p-focused: rgba(69, 143, 255, 1);
    --p-surface-selected: rgba(242, 247, 254, 1);
    --p-surface-selected-hovered: rgba(237, 244, 254, 1);
    --p-surface-selected-pressed: rgba(229, 239, 253, 1);
    --p-icon-on-interactive: rgba(255, 255, 255, 1);
    --p-text-on-interactive: rgba(255, 255, 255, 1);
    --p-action-secondary: rgba(255, 255, 255, 1);
    --p-action-secondary-disabled: rgba(255, 255, 255, 1);
    --p-action-secondary-hovered: rgba(246, 246, 247, 1);
    --p-action-secondary-pressed: rgba(241, 242, 243, 1);
    --p-action-secondary-depressed: rgba(109, 113, 117, 1);
    --p-action-primary: #2ab69a;
    --p-action-primary-disabled: rgba(241, 241, 241, 1);
    --p-action-primary-hovered: #42BEA5;
    --p-action-primary-pressed: #459585;
    --p-action-primary-depressed: #426860;
    --p-icon-on-primary: rgba(255, 255, 255, 1);
    --p-text-on-primary: rgba(255, 255, 255, 1);
    --p-text-primary: #29b498;
    --p-text-primary-hovered: #42BEA5;
    --p-text-primary-pressed: #459585;
    --p-surface-primary-selected: rgba(241, 248, 245, 1);
    --p-surface-primary-selected-hovered: #b1c8c3;
    --p-surface-primary-selected-pressed: #a1b5b1;
    --p-border-critical: rgba(253, 87, 73, 1);
    --p-border-critical-subdued: rgba(224, 179, 178, 1);
    --p-border-critical-disabled: rgba(255, 167, 163, 1);
    --p-icon-critical: rgba(215, 44, 13, 1);
    --p-surface-critical: rgba(254, 211, 209, 1);
    --p-surface-critical-subdued: rgba(255, 244, 244, 1);
    --p-surface-critical-subdued-hovered: rgba(255, 240, 240, 1);
    --p-surface-critical-subdued-pressed: rgba(255, 233, 232, 1);
    --p-surface-critical-subdued-depressed: rgba(254, 188, 185, 1);
    --p-text-critical: rgba(215, 44, 13, 1);
    --p-action-critical: rgba(216, 44, 13, 1);
    --p-action-critical-disabled: rgba(241, 241, 241, 1);
    --p-action-critical-hovered: rgba(188, 34, 0, 1);
    --p-action-critical-pressed: rgba(162, 27, 0, 1);
    --p-action-critical-depressed: rgba(108, 15, 0, 1);
    --p-icon-on-critical: rgba(255, 255, 255, 1);
    --p-text-on-critical: rgba(255, 255, 255, 1);
    --p-interactive-critical: rgba(216, 44, 13, 1);
    --p-interactive-critical-disabled: rgba(253, 147, 141, 1);
    --p-interactive-critical-hovered: rgba(205, 41, 12, 1);
    --p-interactive-critical-pressed: rgba(103, 15, 3, 1);
    --p-border-warning: rgba(185, 137, 0, 1);
    --p-border-warning-subdued: rgba(225, 184, 120, 1);
    --p-icon-warning: rgba(185, 137, 0, 1);
    --p-surface-warning: rgba(255, 215, 157, 1);
    --p-surface-warning-subdued: rgba(255, 245, 234, 1);
    --p-surface-warning-subdued-hovered: rgba(255, 242, 226, 1);
    --p-surface-warning-subdued-pressed: rgba(255, 235, 211, 1);
    --p-text-warning: rgba(145, 106, 0, 1);
    --p-border-highlight: rgba(68, 157, 167, 1);
    --p-border-highlight-subdued: rgba(152, 198, 205, 1);
    --p-icon-highlight: rgba(0, 160, 172, 1);
    --p-surface-highlight: rgba(164, 232, 242, 1);
    --p-surface-highlight-subdued: rgba(235, 249, 252, 1);
    --p-surface-highlight-subdued-hovered: rgba(228, 247, 250, 1);
    --p-surface-highlight-subdued-pressed: rgba(213, 243, 248, 1);
    --p-text-highlight: rgba(52, 124, 132, 1);
    --p-border-success: #29b498;
    --p-border-success-subdued: #9BD5CA;
    --p-icon-success: #29b498;
    --p-surface-success: #7be4d1fa;
    --p-surface-success-subdued: rgba(241, 248, 245, 1);
    --p-surface-success-subdued-hovered: rgba(236, 246, 241, 1);
    --p-surface-success-subdued-pressed: rgba(226, 241, 234, 1);
    --p-text-success: #29b498;
    --p-decorative-one-icon: rgba(126, 87, 0, 1);
    --p-decorative-one-surface: rgba(255, 201, 107, 1);
    --p-decorative-one-text: rgba(61, 40, 0, 1);
    --p-decorative-two-icon: rgba(175, 41, 78, 1);
    --p-decorative-two-surface: rgba(255, 196, 176, 1);
    --p-decorative-two-text: rgba(73, 11, 28, 1);
    --p-decorative-three-icon: #00886D;
    --p-decorative-three-surface: #92D6C9;
    --p-decorative-three-text: rgba(0, 47, 25, 1);
    --p-decorative-four-icon: #006a54;
    --p-decorative-four-surface: #C3EDE5;
    --p-decorative-four-text: rgba(0, 45, 45, 1);
    --p-decorative-five-icon: rgba(174, 43, 76, 1);
    --p-decorative-five-surface: rgba(253, 201, 208, 1);
    --p-decorative-five-text: rgba(79, 14, 31, 1);
    --p-border-radius-slim: 0.125rem;
    --p-border-radius-base: 0.25rem;
    --p-border-radius-wide: 0.5rem;
    --p-border-radius-full: 50%;
    --p-card-shadow: 0px 0px 5px var(--p-shadow-from-ambient-light), 0px 1px 2px var(--p-shadow-from-direct-light);
    --p-popover-shadow: -1px 0px 20px var(--p-shadow-from-ambient-light), 0px 1px 5px var(--p-shadow-from-direct-light);
    --p-modal-shadow: 0px 26px 80px var(--p-shadow-from-dim-light), 0px 0px 1px var(--p-shadow-from-dim-light);
    --p-top-bar-shadow: 0 2px 2px -1px var(--p-shadow-from-direct-light);
    --p-button-drop-shadow: 0 1px 0 rgba(0, 0, 0, 0.05);
    --p-button-inner-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.2);
    --p-button-pressed-inner-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.15);
    --p-override-none: none;
    --p-override-transparent: transparent;
    --p-override-one: 1;
    --p-override-visible: visible;
    --p-override-zero: 0;
    --p-override-loading-z-index: 514;
    --p-button-font-weight: 500;
    --p-non-null-content: '';
    --p-choice-size: 1.25rem;
    --p-icon-size: 0.625rem;
    --p-choice-margin: 0.0625rem;
    --p-control-border-width: 0.125rem;
    --p-banner-border-default: inset 0 1px 0 0 var(--p-border-neutral-subdued), inset 0 0 0 1px var(--p-border-neutral-subdued);
    --p-banner-border-success: inset 0 1px 0 0 var(--p-border-success-subdued), inset 0 0 0 1px var(--p-border-success-subdued);
    --p-banner-border-highlight: inset 0 1px 0 0 var(--p-border-highlight-subdued), inset 0 0 0 1px var(--p-border-highlight-subdued);
    --p-banner-border-warning: inset 0 1px 0 0 var(--p-border-warning-subdued), inset 0 0 0 1px var(--p-border-warning-subdued);
    --p-banner-border-critical: inset 0 1px 0 0 var(--p-border-critical-subdued), inset 0 0 0 1px var(--p-border-critical-subdued);
    --p-badge-mix-blend-mode: luminosity;
    --p-thin-border-subdued: 1px solid var(--p-border-subdued);
    --p-text-field-spinner-offset: 0.125rem;
    --p-text-field-focus-ring-offset: -0.25rem;
    --p-text-field-focus-ring-border-radi: 0.4375rem;
    --p-button-group-item-spacing: -0.0625rem;
    --p-duration-1-0-0: 100ms;
    --p-duration-1-5-0: 150ms;
    --p-ease-in: cubic-bezier(0.5, 0.1, 1, 1);
    --p-ease: cubic-bezier(0.4, 0.22, 0.28, 1);
    --p-range-slider-thumb-size-base: 1rem;
    --p-range-slider-thumb-size-active: 1.5rem;
    --p-range-slider-thumb-scale: 1.5;
    --p-badge-font-weight: 400;
    --p-frame-offset: 0px;
    //Primaire HC (bleu vert) : rgb(50,216,184) #2fd8b6
    //Secondaire HC (violet) :  rgb(89,3,75)    #59034b
}